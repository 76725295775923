/**
 * @param {Object} user
 *
 * @return {String}
 */
 function getDisplayName(user) {
  
    if (user.prenom && user.nom) {
      return user.prenom + " " + user.nom;
    }
  
    if (user.nom) {
      return user.name;
    }

    return user.email;
  }
  
  /**
   * @param {Object} user
   *
   * @return {Object}
   */
  export default function formatUser(user) {
    return {
      email: user.email,
      iduser: user.iduser,
      displayName: getDisplayName(user)
    };
  }