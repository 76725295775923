import fetchJSON from "../fetchJSON";
/**
 * @param {Object} config
 * @param {String} config.connectedURL
 * @param {String} config.loginURL
 * @param {String} config.logoutURL
 *
 * @return {Object}
 */
export default function getHTTPStrategy({ loginURL, logoutURL }, lsKey = "user") {
    return {
        /**
         * @return {Promise}
         */
        getConnectedUser() {
            const user = localStorage.getItem(lsKey);
            return Promise.resolve(user ? JSON.parse(user) : null);
        },

        /**
         * @param {String} email
         * @param {String} password
         *
         * @return {Promise}
         */
        login(email, password) {
            return fetchJSON(loginURL, {
                method: "POST",
                body: { email, password }
            });
        },

        /**
         * @return {Promise}
         */
        logout() {
            return fetch(logoutURL);
        }
    };
}