import buildFetchParams from "./buildFetchParams";

/**
 * @param {String} url
 * @param {Object} [params]
 *
 * @return {Promise}
 */
export default function fetchJSON(url, params) {
  return fetch(url, buildFetchParams(params))
    .then(response => {
      if (!response.ok) {
        throw new Error(response.status);
      }

      return response.json();
    });
}