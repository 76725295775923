import formatUser from "./formatUser";
import fetchJSON from "./fetchJSON";

export default {
  install: (Vue, { strategy }) => {
    Vue.prototype.$auth = new Vue({
      data() {
        return {
          user: null
        };
      },

      methods: {
        setUser(user) {
          if (user) {
            this.user = formatUser(user);
          }
        },

        init() {
          return strategy.getConnectedUser()
            .then(user => {
              this.setUser(user);
            })
            .catch(console.error);
        },

        login(email, password) {
          return strategy.login(email, password)
            .then(user => {
              this.setUser(user);
              if (user) {
                localStorage.setItem("user", JSON.stringify(user));
              }
            })
            .catch(console.error);
        },

        logout() {
          return strategy.logout()
            .then(() => {
              this.user = null;
              localStorage.removeItem("user");
            })
            .catch(console.error);
        },

        fetch(url, params) {
          return fetchJSON(url, {
            ...params || {}
          });
        }
      }
    });
  }
};