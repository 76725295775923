import Vue from "vue";
import AuthPlugin from "./plugin";
import getAuthentication from "./strategies/getHTTPStrategy";
import AuthWrapper from "./components/AuthWrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

let configurl = {
  'connectedURL': 'https://21sofcot.urevent.fr/Scanapi/connected',
  'loginURL': 'https://21sofcot.urevent.fr/Scanapi/login',
  'logouURL': 'https://21sofcot.urevent.fr/Scanapi/logout',
};

Vue.use(AuthPlugin, {
  strategy: getAuthentication(configurl)
});


new Vue({
  render: h => h(AuthWrapper)
}).$mount("#app");