/**
 * @param {Object} [params]
 *
 * @return {Object}
 */
 export default function buildFetchParams(params = {}) {
    return {
      ...params,
      ..."body" in params && { body: JSON.stringify(params.body) },
      headers: {
        ..."headers" in params && params.headers,
        "Content-Type": "headers" in params && "Content-Type" in params.headers ? params.headers["Content-Type"] : "application/json"
      }
    };
  }